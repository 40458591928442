
import {computed, defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ISearchField} from "@/core/entity/ISearchField";
import {OPERATOR} from "@/core/config/Constant";
import store from "@/store";
import {CompanySelect, LoadPanel, SearchEntityEvent} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "ListComplete",
  props: {
    status: {required: true, type: String, default: 'NEW'},
  },
  components: {
    BaseSelect,
     KtDatatable, Entities,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Aetna Invoices',
        [
          {link: false, router: '', text: 'Complete'}
        ]
      )
    })
    const fields = ['all']
    const fieldSearch: ISearchField[] = [
      {column: 'aetnaId', type: 'TEXT', operator: OPERATOR.EQ, value: '', name: 'Aetna ID'},
      {column: 'adjudicationStatus', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'TO_ADJUDICATE', name: ''},
      {column: 'invoiceType', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'AETNA', name: ''},
      {column: 'aetnaStatus', type: 'HIDDEN', operator: OPERATOR.EQ, value: 'COMPLETE', name: ''},
    ]
    const tableHeader = ref([
      {
        name: "AetnaId",
        key: "aetnaId",
        sortable: true,
      },
      {
        name: "Invoice#",
        key: "invoiceNumber",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNumber",
        sortable: true,
      },
      {
        name: "Claim#",
        key: "claimNumber",
        sortable: true,
      },
      {
        name: "Insured",
        key: "insured",
        sortable: true,
      },
      {
        name: 'Billed',
        key: 'billed',
        sortable: true,
      },
    ]);
    const invoiceViewPage = computed(() => store.state.InvoiceModule.invoiceViewPage)
    return {
      fields,
      fieldSearch,
      invoiceViewPage,
      ...CompanySelect(),
      ...SearchEntityEvent(Actions.INVOICE_SEARCH, fieldSearch, fields, [], 'invoiceView'),
      tableHeader,
      ...LoadPanel(),
    }
  },
  methods: {}
})
